import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7150a3dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selectable-days" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: index,
        class: _normalizeClass(["selectable-day", { selected: _ctx.isSelected(index + 1) }]),
        onClick: ($event: any) => (_ctx.onSelect(index + 1))
      }, _toDisplayString(day), 11, _hoisted_2))
    }), 128))
  ]))
}